import React, { useContext } from "react";
import { Script } from "gatsby";
import TrackingContext from "../../context/TrackingContext";

const TaboolaWebPush = (): React.JSX.Element => {
  const { utm_source } = useContext(TrackingContext);

  if (utm_source && !utm_source.includes("fb-")) {
    return (
      <>
        <Script
          strategy="idle"
          src={`https://cdn.taboola.com/webpush/publishers/${process.env.GATSBY_TABOOLA_PUSH_PUB_ID}/taboola-push-sdk.js`}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default TaboolaWebPush;
